<template>

  <el-dialog
      title="清流表单提交"
      v-if="showObj.isShow"
      :visible.sync="showObj.isShow"
      width="952">
    <iframe id="questionBack" ref="questionBack" :src="url" height="600" width='100%'
            frameborder="0"></iframe>
  </el-dialog>

</template>

<script>
import {getUserId} from "../utils/auth";

export default {
  name: "ClearStream",
  props: {
    showObj: {
      type: Object,
      require: true
    }
  },
  data() {
    return {}
  },
  methods: {
    closeIframe() {
      this.showObj.isShow = false
    }
  },
  computed: {
    url: function () {
      let userId = getUserId()
      let urlPrefix = window.location.host
      return `https://qingflow.com/f/d053574b?qfchannel=${urlPrefix}_`
    }
  },
  mounted() {
    //监听postMessage传过来的值
    let that = this
      console.log(that.url)
    // window.addEventListener("message", function (messageEvent) {
    //   that.closeIframe()
    // }, false)
  }
}

</script>

<style scoped>
/deep/.el-dialog {
    margin-top: 10vh;
    width: 70%;
}
</style>
